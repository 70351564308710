export interface SeoData {
  h1: string;
  title: string;
  keywords: string;
  description: string;
}

export interface ImageItem {
  webp: string;
  original: string;
  source: string;
}

export interface ImageSEO {
  image_title: string;
  image_alt: string;
}

export interface OgData {
  title: string;
  description: string;
  image: ImageItem;
}

export type ButtonTriggerType = 'form' | 'link' | 'link_external' | 'doctor_execute';

export interface BlockButtonTrigger {
  type: ButtonTriggerType;
  content: string;
}

export type PageTemplate =
  | 'doctors-category'
  | 'doctors-detail'
  | 'services-list'
  | 'services-detail'
  | 'page-list'
  | 'page-list-sidebar'
  | 'page-detail'
  | 'page-index-detail'
  | 'services-category'
  | 'unique-placeholder'
  | '404-error';

export interface PageResult {
  id: number;
  slug: string;
  full_slug: string;
  title: string;
  image: ImageItem;
  preview_text: string;
  template: PageTemplate;
  publish_from: string;
  seo: SeoData;
  og: OgData;
  image_seo: ImageSEO;
  is_filters_displayed: boolean;
}

export type Breadcrumb = PageResult;
export type BockType =
  | 'BlockText'
  | 'BlockImage'
  | 'BlockSlider'
  // | 'BlockGallery'
  | 'BlockDocs'
  | 'BlockSpoiler'
  | 'BlockImageTextLeft'
  | 'BlockImageTextRight'
  | 'BlockDynamicForm'
  | 'BlockSubPages'
  | 'BlockTextWithButton'
  | 'BlockRating'
  | 'BlockBanner'
  | 'BlockReels'
  | 'BlockAdvantages'
  | 'BlockDifference'
  | 'BlockVideoReview'
  | 'BlockReview'
  | 'BlockSliderTextLeftRight'
  | 'BlockPrice'
  | 'BlockVideo'
  | 'BlockAdvantagesVideo'
  | 'BlockOffice'
  | 'BlockSliderCertificates'
  | 'BlockTabs'
  | 'BlockMainBanner'
  | 'BlockServiceCatalogCategory'
  | 'BlockServiceCategory'
  | 'BlockDoctorCategory'
  | 'BlockAuthor'
  | 'BlockTextForm';

export interface BaseBlock {
  name?: string;
  id: number;
  type?: BockType;
  resourcetype?: BockType | string;
}

export interface BlockText extends BaseBlock {
  text: string;
}

export interface BlockImage extends BaseBlock {
  image: ImageItem;
  image_seo: ImageSEO;
}

export interface BlockSliderMedia {
  name: string;
  type: 'image' | 'video_link';
  image: ImageItem;
  image_seo: ImageSEO;
  link: string;
  description: string;
}

export interface BlockSlider extends BaseBlock {
  media: BlockSliderMedia[];
}

export interface BlockGalleryImage {
  image: ImageItem;
  image_seo: ImageSEO;
}

export interface BlockGallery extends BaseBlock {
  images: BlockGalleryImage[];
}

export interface BlockDocsFile {
  title: string;
  file: string;
  file_name: string;
  file_extension: string;
  file_size: number;
  file_size_formatted: string;
}

export interface BlockDocs extends BaseBlock {
  files: BlockDocsFile[];
}

export interface BlockSpoilerText {
  title: string;
  text: string;
  answer: string;
  doctor: BlockDoctorsCard | null;
}

export interface BlockSpoiler extends BaseBlock {
  texts: BlockSpoilerText[];
}

export interface BlockImageText extends BaseBlock {
  text: string;
  image: ImageItem;
  image_seo: ImageSEO;
  image_is_rounded: boolean;
  button_text: string | null;
  button_trigger: BlockButtonTrigger | null;
}

export type BlockImageTextLeft = BlockImageText;
export type BlockImageTextRight = BlockImageText;

export interface BlockSliderTextLeftRight extends BaseBlock {
  media: BlockImageTextLeft[] | BlockImageTextRight[];
}

export interface BlockMap extends BaseBlock {
  latitude: number;
  longitude: number;
}

export interface BlockSubPages extends BaseBlock {
  pages_count: number;
  page: PageResult; // Раздел
  subpages: PageResult[]; // страницы раздела
}

export interface BlockBanner extends BaseBlock {
  title: string;
  text: string;
  button_text: string | null;
  image: ImageItem;
  imageSeo: ImageSEO;
  button_trigger: BlockButtonTrigger | null;
}

export interface BlockAdvantages extends BaseBlock {
  name: string;
  preview_text: string;
  media: {
    name: string;
    text: string;
  }[];
}

export interface BlockDifference extends BaseBlock {
  media: {
    text: string;
    image_seo: {
      image_before_title: string;
      image_before_alt: string;
      image_after_title: string;
      image_after_alt: string;
    };
    image_before: ImageItem;
    image_after: ImageItem;
  }[];
}

export interface BlockVideoReview extends BaseBlock {
  reviews: {
    title: string;
    preview_text: string;
    text: string;
    video: string;
    image: ImageItem;
    image_seo: ImageSEO;
    link: string;
    full_slug: string;
    review_media_orientation: 'horizontal' | 'vertical';
  }[];
}

export interface BlockReview extends BaseBlock {
  reviews: {
    title: string;
    preview_text: string;
    text: string;
    video: string;
    image: ImageItem;
    image_seo: ImageSEO;
    link: string;
    full_slug: string;
    review_date: string | null;
  }[];
}

export interface BlockTextWithButton extends BaseBlock {
  button_text: string | null;
  button_trigger: BlockButtonTrigger | null;
  text: string;
}

export interface RatingItem {
  value: number;
  image: ImageItem;
  image_seo: ImageSEO;
}

export interface BlockRating extends BaseBlock {
  text: string;
  media: RatingItem[];
}

export interface ReelsItem {
  image: ImageItem;
  image_seo: ImageSEO;
  text: string;
  oid: number;
  id: number;
}

export interface BlockReels extends BaseBlock {
  media: ReelsItem[];
}

export type BlockTextFormBackground = 'dark' | 'dark-with-logo' | 'light' | 'lighten';

export interface BlockTextForm extends BaseBlock {
  text: string;
  background: BlockTextFormBackground;
  form_title: string;
  form_model: FormModel;
}

export interface BlockPricesServicesItem {
  id: number;
  title: string;
  price: string | null;
}

export interface BlockPricesItem {
  name: string;
  text: string;
  price: string;
  services: BlockPricesServicesItem[];
}

export interface BlockPrice extends BaseBlock {
  text: string;
  prices: BlockPricesItem[];
}

export interface BlockVideo extends BaseBlock {
  image: ImageItem;
  image_seo: ImageSEO;
  video: string;
  link: string;
}

export interface BlockAdvantagesVideo extends BaseBlock {
  media: {
    image: ImageItem;
    image_seo: ImageSEO;
    video: string;
    link: string;
    name: string;
    text: string;
  }[];
}

export interface OfficeItem {
  id: number;
  medflex_id: string;
  title: string;
  address: string;
  working_time: string;
  image: ImageItem;
  latitude: number | null;
  longitude: number | null;
  phone: string;
  blocks: Array<BlockMap | BlockRating>;
}

export interface BlockOffice extends BaseBlock {
  offices: OfficeItem[];
}

export interface BlockSliderCertificates extends BaseBlock {
  media: {
    image: ImageItem;
    image_seo: ImageSEO;
  };
}

export interface BlockDoctorsCard {
  id: number;
  full_name: string;
  experience: string;
  experience_from: string;
  description: string;
  link: string;
  image: ImageItem;
  image_seo: ImageSEO;
  preview_text: string;
  full_slug: string;
}

export interface BlockTabsItem {
  button_text: string | null;
  button_trigger: BlockButtonTrigger | null;
  name: string;
  title: string;
  text: string;
  type: 'text' | 'category';
  icon: ImageItem;
  image: ImageItem;
  image_seo: ImageSEO;
  doctors: BlockDoctorsCard[] | null;
  professions?: {
    id: number;
    title: string;
  }[];
}

export interface BlockTabs extends BaseBlock {
  media: BlockTabsItem[];
}

export interface BlockAuthor extends BaseBlock {
  doctor: BlockDoctorsCard | null;
  button_text: string;
  button_trigger: BlockButtonTrigger;
  form_title: string;
  created: string;
  updated: string;
  verified: string;
}

export interface BlockDoctorCategory extends BaseBlock {
  doctors: BlockDoctorsCard[];
}

export interface BlockMainBanner extends BaseBlock {
  button_text: string;
  button_trigger: BlockButtonTrigger;
  form_title: string;
  text: string;
  image: ImageItem;
  image_seo: ImageSEO;
  button_text_phone: string;
  phone: string;
}

export type CategoryAgeType = 'adult' | 'children';

export enum CategoryAgeEnum {
  ADULT = 'adult',
  CHILDREN = 'children',
}

export interface ServiceCatalogCategoryItem {
  title: string;
  icon: null | string;
  full_slug: string;
  age: CategoryAgeType;
  children: ServiceCatalogCategoryItem[];
}

export interface BlockServiceCatalogCategory extends BaseBlock {
  categories: ServiceCatalogCategoryItem[];
}

export interface BlockServiceCategory extends BaseBlock {
  media: {
    title: string;
    image: ImageItem;
    image_seo: ImageSEO;
    full_slug: string;
  }[];
}

export interface FormFieldChoice {
  value: string;
  title: string;
}

export interface FormFieldAttributes {
  [key: string]: string;
}

export type FormFieldType =
  | 'django.forms.CharField'
  | 'core.dynamicforms.fields.TextAreaField'
  | 'django.forms.IntegerField'
  | 'django.forms.FloatField'
  | 'django.forms.BooleanField'
  | 'django.forms.EmailField'
  | 'core.dynamicforms.fields.PhoneField'
  | 'django.forms.DateField'
  | 'django.forms.TimeField'
  | 'django.forms.DateTimeField'
  | 'django.forms.FileField'
  | 'django.forms.ImageField'
  | 'django.forms.ChoiceField'
  | 'django.forms.MultipleChoiceField';

export interface FormField {
  id: number;
  title: string;
  type: FormFieldType;
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  choices: FormFieldChoice[];
  attributes: FormFieldAttributes;
}

export interface FormModel {
  id: number;
  title: string;
  help_text: string;
  form_title: string;
  key: string;
  fields: FormField[];
}

export interface BlockDynamicForm extends BaseBlock {
  form_model: FormModel;
}

export type PageBlocks = BlockText &
  BlockImage &
  BlockSlider &
  BlockVideo &
  // BlockGallery &
  BlockDocs &
  BlockSpoiler &
  BlockImageTextLeft &
  BlockImageTextRight &
  BlockDynamicForm &
  BlockSubPages &
  BlockTextWithButton &
  BlockRating &
  BlockReels &
  BlockBanner &
  BlockAdvantages &
  BlockDifference &
  BlockVideoReview &
  BlockReview &
  BlockTextForm &
  BlockPrice &
  BlockAdvantagesVideo &
  BlockOffice &
  BlockSliderCertificates &
  BlockTabs &
  BlockMainBanner &
  BlockServiceCatalogCategory &
  BlockServiceCategory &
  BlockDoctorCategory &
  BlockAuthor &
  BlockSliderTextLeftRight;

export type OrderingParamValue =
  | '-created'
  | '-publish_from'
  | '-sort'
  | 'created'
  | 'publish_from'
  | 'sort';

export interface ApiPageParams {
  image_opts?: string;
  ordering?: OrderingParamValue;
  page?: number; // Номер страницы в постраничном наборе результатов.
  page_size?: number; // Количество результатов, возвращаемых на страницу.
  parent?: number;
  search?: string;
  services: number;
  doctors: number;
  template_type?: 'detail' | 'list';
  offices?: number;
}

//ответ на api/pages
export interface ApiPageResponse {
  count: number;
  next: string;
  seo: SeoData;
  og: OgData;
  previous: string;
  results: PageResult[];
}

export interface ApiDoctorsSchedulesItem {
  id: number;
  doctor_id: number;
  office_id: number;
  cells: [
    {
      date: string;
      times: {
        id: number;
        start: Date;
        end: Date;
      };
    },
  ];
}

export type ApiDoctorsSchedulesResponse = ApiDoctorsSchedulesItem[];

export interface ApiPageSlugParams {
  [key: string]: string;

  // image_opts?: string;
}

// ответ на запрос api/pages/{slug}
export type ApiPageSlugResponse = PageResult;

export interface SidebarItem {
  id: number;
  title: string;
  slug: string;
  seo: SeoData;
  children: string[];
}

// api/pages/{slug}/extended/
export interface ApiPageExtendedResponse extends PageResult {
  breadcrumbs: Breadcrumb[];
  blocks: PageBlocks[];
  sidebar: SidebarItem[];
}

// /api/services

export interface ServicesResults {
  id: number;
  title: string;
  icon: string;
  age: 'adult' | 'children';
  full_slug: string;
  children: ServicesResults[];
}

export interface ApiServicesResponse {
  count: number;
  next: string;
  previous: string;
  results: ServicesResults[];
}

export interface DoctorsCard {
  id: number;
  slug?: string;
  full_slug: string;
  title: string;
  primary_doctor: boolean;
  image: ImageItem;
  category: {
    id: number;
    title: string;
  };
  extra_categories: {
    id: number;
    title: string;
  }[];
  link: string;
  experience: string;
  seo: SeoData;
  og: OgData;
  image_seo: ImageSEO;
  description: string;
}

export interface ApiDoctorsResponse {
  count: number;
  next: string;
  previous: string;
  seo: SeoData;
  og: OgData;
  results: DoctorsCard[];
}

export interface ApiDoctorsParams {
  affiliate?: string;
  image_opts?: string;
  ordering?: OrderingParamValue;
  page?: number;
  page_size?: number;
  profession_id?: number;
  doctors_image_opts: string;
}

export interface DoctorCategory {
  id: 0;
  title: 'string';
}

export interface ApiDoctorPageResponse {
  id: 0;
  medflex_id: string;
  full_slug: string;
  title: string;
  primary_doctor: boolean;
  image: ImageItem;
  category: DoctorCategory;
  extra_categories: DoctorCategory[];
  link: string;
  media_orientation: 'vertical' | 'horizontal';
  experience: string;
  experience_from: string;
  description: string;
  seo: SeoData;
  og: OgData;
  image_seo: ImageSEO;
  breadcrumbs: {
    title: string;
    link: string;
  }[];
  tabs: [
    {
      name: string;
      blocks: PageBlocks[];
    },
  ];
}

export interface DoctorsAppointmentCreateBody {
  doctor_id: number;
  office_id: number;
  dt_start: string;
  dt_end: string;
  full_name: string;
  comment: string;
  mobile_phone: string;
}

export interface FiltersCatalogs {
  id: number;
  title: string;
}

export type FiltersCatalogsResponse = FiltersCatalogs[];
