<template>
  <header class="header">
    <div class="header__wrapper">
      <NuxtLink class="header__logo" :to="{ name: 'home', params: { chapters: 'index' } }">
        <NuxtIcon name="IconHeaderLogo" filled />
      </NuxtLink>

      <div class="header__middle" :class="{ 'header__middle-open': isOpenBurger }">
        <div v-if="isOpenBurger" class="header__middle-mobile">
          <NuxtIcon name="IconCancel" @click="onCloseBurger" />
        </div>

        <div class="header__nav">
          <div class="header__nav-services">
            <div class="header__nav-search">
              <UIButton variant="white" size="l" @click="isOpenSearchModal = true">
                <NuxtIcon name="IconSearch" />
                Поиск
              </UIButton>
            </div>

            <UIButton
              class="header__nav-services-pc"
              variant="primary"
              size="l"
              @click="isServicesOpen = true"
            >
              <NuxtIcon name="IconMenu" />
              Услуги
            </UIButton>
            <div class="header__nav-services-mob header__menu-link" @click="isServicesOpen = true">
              Услуги
              <NuxtIcon name="IconChevronVerticalSmall" />
            </div>
          </div>

          <nav class="header__menu">
            <ul class="header__menu-list">
              <li
                v-for="(item, index) in menuItems"
                :key="`header_${index}_${item.slug}`"
                class="header__menu-item"
              >
                <NuxtLink
                  class="header__menu-link"
                  :to="
                    item.type === 'direct_link'
                      ? item.url
                      : { name: 'home', params: { chapters: item.slug.split('/') } }
                  "
                  :target="item.target"
                  @click="item.children.length > 0 ? toggleSubMenu(index) : ''"
                >
                  {{ item.title }}
                  <NuxtIcon
                    v-if="item.children.length > 0"
                    ref="target"
                    name="IconChevronVerticalSmall"
                  />
                </NuxtLink>

                <ul v-if="openSubMenuIndex === index" class="header__sub-menu">
                  <li
                    v-for="(subItem, subIndex) in item.children"
                    :key="`header_${index}_${item.slug}_${subIndex}`"
                    class="header__sub-menu-item"
                  >
                    <NuxtLink
                      class="header__sub-menu-link"
                      :to="
                        subItem.type === 'direct_link'
                          ? subItem.url
                          : { name: 'home', params: { chapters: subItem.slug.split('/') } }
                      "
                      :target="subItem.target"
                    >
                      {{ subItem.title }}
                    </NuxtLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        <div v-if="config?.socials > 0" class="header__socials">
          <template v-for="item in config.socials">
            <NuxtLink v-if="item.link.length > 0" :key="item.name" :to="item.link" target="_blank">
              <NuxtIcon v-if="item.name" :name="`IconSocial${item.name}`" filled />
            </NuxtLink>
          </template>
        </div>
      </div>

      <div
        ref="headerAffiliates"
        class="header__affiliates"
        :class="{ 'header__affiliates-open': isOpenAffiliates }"
      >
        <div class="header__affiliates-mobile">
          <NuxtIcon name="IconCancel" @click="isOpenAffiliates = false" />
          <h2>Связаться с нами</h2>
        </div>
        <div v-for="affiliate in affiliates" :key="affiliate.id" class="header__affiliates-item">
          <a
            :href="`tel:${affiliate.phone}`"
            class="header__affiliates-phone"
            @click="goalsClickPhone"
          >
            {{ affiliate.phone }}
          </a>
          <div class="header__affiliates-pc header__affiliates-text">
            <NuxtIcon name="IconMetro" />
            {{ affiliate.title }}
          </div>
          <div class="header__affiliates-mob header__affiliates-text">
            <NuxtIcon name="IconClock" filled />
            {{ affiliate.working_time }}
          </div>
          <div class="header__affiliates-mob header__affiliates-text">
            <NuxtIcon name="IconMapPin" filled />
            {{ affiliate.address }}
          </div>
        </div>
      </div>

      <div class="header__buttons">
        <div class="header__button header__button-mail-pc">
          <CButtonTrigger
            button-title="Онлайн запись"
            button-variant="primary"
            button-size="l"
            :button-trigger="{
              type: 'doctor_execute',
              content: '',
            }"
          />
        </div>
        <div class="header__button header__button-search-mob">
          <UIButtonRounded icon-name="IconSearch" @click="isOpenSearchModal = true" />
        </div>
        <div class="header__button header__button-search-pc">
          <UIButton variant="white" size="l" @click="isOpenSearchModal = true">
            <NuxtIcon name="IconSearch" />
            Поиск
          </UIButton>
        </div>
        <div class="header__button header__button-phone">
          <UIButtonRounded icon-name="IconPhone" @click="isOpenAffiliates = true" />
        </div>
        <div class="header__button header__button-mail-mob">
          <UIButtonRounded icon-name="IconSendMail" />
        </div>
        <div class="header__button">
          <UIButtonRounded
            class="header__button header__buttons-glasses bvi-open"
            icon-name="IconGlasses"
          />
        </div>
        <div class="header__button header__button-burger">
          <UIButtonRounded icon-name="IconMenu" @click="onOpenBurger" />
        </div>
      </div>
    </div>
    <CHeaderServices
      v-if="isServicesOpen"
      ref="servicesList"
      @on-close="isServicesOpen = !isServicesOpen"
    />
    <CHeaderSearch v-if="isOpenSearchModal" v-model="isOpenSearchModal" />
  </header>
</template>

<script setup lang="ts">
import type { MenuItem } from '@/types/menu';
import './styles.scss';
import { onMounted, ref, useRouter, useState } from '#imports';
import { onClickOutside } from '@vueuse/core';
import UIButton from '@/components/UI/button/UIButton.vue';
import UIButtonRounded from '@/components/UI/buttonRounded/UIButtonRounded.vue';
import CHeaderServices from '~/components/headerServices/CHeaderServices.vue';
import CHeaderSearch from '~/components/headerSearch/CHeaderSearch.vue';
import CButtonTrigger from '~/components/buttonTrigger/CButtonTrigger.vue';
import type { OfficeItem } from '~/types/pages';
import { yandexRichGoals } from '~/composables/yandexRichGoals';
import type { ClientConfigResponse } from '~/types/client/init';

interface Props {
  menuItems: MenuItem[];
  headerPhone?: string;
  headerEmail?: string;
}

defineProps<Props>();

const openSubMenuIndex = ref<number | null>(null);
const headerAffiliates = ref();
const toggleSubMenu = (index: number) => {
  openSubMenuIndex.value = openSubMenuIndex.value === index ? null : index;
};

// const isSubMenuOpen = (index: number) => {
//   return ;
// };

const target = ref(null);

const isOpenBurger = ref(false);
const isOpenAffiliates = ref(false);

const onCloseBurger = () => (isOpenBurger.value = false);
const onOpenBurger = () => (isOpenBurger.value = true);

const isServicesOpen = ref(false);

const servicesList = ref(null);
function bindClickEvents(firstElementSelector: string, secondElementSelector: string) {
  const firstElement = document.querySelector(firstElementSelector);
  const secondElement = document.querySelector(secondElementSelector);

  if (!firstElement || !secondElement) {
    console.error('One or both elements not found');
    return;
  }

  firstElement.addEventListener('click', () => {
    secondElement.click();
  });
}

onMounted(() => {
  const firstElement = document.querySelector('.header__button-mail-pc .button');
  const secondElement = document.querySelector('.header__button-mail-mob .button-rounded');

  secondElement?.addEventListener('click', () => {
    firstElement?.click();
  });

  onClickOutside(target, () => {
    openSubMenuIndex.value = null;
  });

  onClickOutside(headerAffiliates, () => {
    isOpenAffiliates.value = false;
  });

  onClickOutside(servicesList, () => {
    isServicesOpen.value = false;
  });
});

const router = useRouter();

const { goalsClickPhone } = yandexRichGoals();

router.beforeEach(() => {
  isOpenBurger.value = false;
  isServicesOpen.value = false;
  openSubMenuIndex.value = null;
});

const isOpenSearchModal = ref(false);

const config = useState<ClientConfigResponse>('config');
const affiliates = useState<OfficeItem[]>('affiliates');
</script>
