import { defineAsyncComponent, Component } from '#imports';
import type {
  BlockAdvantages,
  BlockAdvantagesVideo,
  BlockDifference,
  BlockImageTextLeft,
  BlockImageTextRight,
  BlockReview,
  BlockServiceCategory,
  BlockVideoReview,
  BockType,
  PageBlocks,
} from '~/types/pages';

const textBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/textBlock/CTextBlock.vue'),
);
const docsBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/docsBlock/CDocsBlock.vue'),
);
const imageTextBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/imageTextBlock/CImageTextBlock.vue'),
);
const sliderImageTextBLock = defineAsyncComponent(
  () =>
    import(/* @vite-ignore */ '~/components/blocks/sliderImageTextBlock/CSliderImageTextBlock.vue'),
);
const spoilerBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/spoilerBlock/CSpoilerBlock.vue'),
);
const sliderBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/sliderBlock/CSliderBlock.vue'),
);
const imageBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/imageBlock/CImageBlock.vue'),
);
const dynamicForm = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/simpleFormBlock/CSimpleFormBlock.vue'),
);

const subPagesBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/subPagesBlock/CSubPagesBLock.vue'),
);

const textWithButton = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/textWithButtonBlock/CTextWithButton.vue'),
);

const bannerBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/bannerBlock/CBannerBlock.vue'),
);
const reelsBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/reelsBlock/CReelseBLock.vue'),
);

const ratingBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/ratingBlock/CRatingBlock.vue'),
);
const afterBeforeSliderBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/afterBeforeSlider/CAfterBeforeSlider.vue'),
);

const advantagesBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/adventureBlock/CAdventureBlock.vue'),
);

const videoReviewBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/videoReviewsBlock/CVideoReviewsBlock.vue'),
);

const reviewBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/reviewBlock/CReviewBlock.vue'),
);

const textWithFormBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/textWithForm/CTextWithForm.vue'),
);

const pricesBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/pricesBlock/CPricesBlock.vue'),
);

const videoBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/videoBlock/CVideoBlock.vue'),
);

const advantagesVideoBlock = defineAsyncComponent(
  () =>
    import(/* @vite-ignore */ '~/components/blocks/videoAdvantagesBlock/CVideoAdventagesBlock.vue'),
);

const officeBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/officeBlock/COfficeBlock.vue'),
);

const certificatesBlock = defineAsyncComponent(
  () =>
    import(
      /* @vite-ignore */ '~/components/blocks/certificateSliderBlock/CCertificateSliderBlock.vue'
    ),
);

const tabsBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/tabsBlock/CBlockTabs.vue'),
);

const bannerMainPage = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/mainBannerPage/CBannerMainPage.vue'),
);

const serviceCatalogCategory = defineAsyncComponent(
  () =>
    import(
      /* @vite-ignore */ '~/components/blocks/serviceCatalogCategoryBlock/CServiceCatalogCategoryBlock.vue'
    ),
);

const servicesCategoryBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/serviceCategory/CServiceCategoryBlock.vue'),
);

const doctorsSliderBlock = defineAsyncComponent(
  () =>
    import(/* @vite-ignore */ '~/components/blocks/doctorCategoryBlock/CDoctorCategoryBlock.vue'),
);

const authorBlock = defineAsyncComponent(
  () => import(/* @vite-ignore */ '~/components/blocks/authorBlock/CAuthorBlock.vue'),
);

export function useArticleBlocks() {
  const articleBlocks: Record<BockType, Component | undefined> = {
    BlockAuthor: authorBlock,
    BlockText: textBlock,
    BlockDocs: docsBlock,
    BlockImageTextLeft: imageTextBlock,
    BlockImageTextRight: imageTextBlock,
    BlockSpoiler: spoilerBlock,
    BlockSlider: sliderBlock,
    BlockImage: imageBlock,
    BlockDynamicForm: dynamicForm,
    BlockSubPages: subPagesBlock,
    BlockTextWithButton: textWithButton,
    BlockBanner: bannerBlock,
    BlockReels: reelsBlock,
    BlockSliderTextLeftRight: sliderImageTextBLock,
    BlockRating: ratingBlock,
    BlockAdvantages: advantagesBlock,
    BlockDifference: afterBeforeSliderBlock,
    BlockVideoReview: videoReviewBlock,
    BlockReview: reviewBlock,
    BlockTextForm: textWithFormBlock,
    BlockPrice: pricesBlock,
    BlockVideo: videoBlock,
    BlockAdvantagesVideo: advantagesVideoBlock,
    BlockOffice: officeBlock,
    BlockSliderCertificates: certificatesBlock,
    BlockTabs: tabsBlock,
    BlockMainBanner: bannerMainPage,
    BlockServiceCatalogCategory: serviceCatalogCategory,
    BlockServiceCategory: servicesCategoryBlock,
    BlockDoctorCategory: doctorsSliderBlock,
  };

  const getBlocksProps = (block: PageBlocks, index: number | undefined) => {
    const blockProps: Record<BockType, unknown> = {
      BlockAuthor: {
        id: block.id,
        doctor: block.doctor,
        button_text: block.button_text,
        button_trigger: block.button_trigger,
        form_title: block.form_title,
        created: block.created,
        updated: block.updated,
        verified: block.verified,
      },
      BlockText: {
        id: block.id,
        text: block.text,
        title: block.name,
      },
      BlockDocs: {
        id: block.id,
        files: block.files,
        title: block.name,
      },
      BlockImageTextLeft: {
        image: block.image,
        'image-seo': block.image_seo,
        text: block.text,
        title: block.name,
        isReverse: true,
        imageIsRounded: block.image_is_rounded,
        buttonText: block.button_text,
        buttonTrigger: block.button_trigger,
      },
      BlockImageTextRight: {
        image: block.image,
        'image-seo': block.image_seo,
        text: block.text,
        title: block.name,
        isReverse: false,
        imageIsRounded: block.image_is_rounded,
        buttonText: block.button_text,
        buttonTrigger: block.button_trigger,
      },
      BlockSpoiler: {
        texts: block.texts,
        title: block.name,
      },
      BlockSlider: {
        media: block.media,
        title: block.name,
      },
      BlockImage: {
        title: block.name,
        imageSeo: block.image_seo,
        image: block.image,
      },
      BlockDynamicForm: {
        title: block?.form_title || block.form_model?.title,
        formKey: block.form_model?.key,
        helpText: block.form_model?.help_text,
        fields: block.form_model?.fields,
      },
      BlockSubPages: {
        subpages: block.subpages,
        title: block.name,
      },
      BlockTextWithButton: {
        title: block.name,
        text: block.text,
        buttonText: block.button_text,
        buttonTrigger: block.button_trigger,
      },
      BlockBanner: {
        title: block.name,
        text: block.text,
        buttonText: block.button_text,
        image: block.image,
        imageSeo: block.imageSeo,
        buttonTrigger: block.button_trigger,
      },
      BlockReels: {
        title: block.name,
        media: block.media,
        isLong: false,
      },
      BlockRating: {
        name: block.name,
        text: block.text,
        media: block.media,
      },
      BlockSliderTextLeftRight: {
        title: block.name,
        textBlocks: block.media?.map((item: BlockImageTextLeft | BlockImageTextRight) => ({
          title: item.name,
          image: item.image,
          imageSeo: item.image_seo,
          text: item.text,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          isReverse: item.image_position !== 'right',
          imageIsRounded: item.image_is_rounded,
          button: item.button_text,
          buttonTrigger: item.button_trigger,
        })),
      },
      BlockAdvantages: {
        title: block.name,
        previewText: block.preview_text,
        items: (block.media as BlockAdvantages['media'])?.map(item => ({
          title: item.name,
          text: item.text,
        })),
      },
      BlockDifference: {
        title: block.name,
        items: (block.media as BlockDifference['media'])?.map(item => ({
          image1: item.image_before,
          image2: item.image_after,
          image1Seo: {
            image_title: item.image_seo?.image_before_title,
            image_alt: item.image_seo?.image_before_alt,
          },
          image2Seo: {
            image_title: item.image_seo?.image_after_title,
            image_alt: item.image_seo?.image_after_alt,
          },
          description: item.text,
        })),
      },
      BlockVideoReview: {
        title: block.name,
        reviews: (block.reviews as BlockVideoReview['reviews'])?.map(item => ({
          image: item.image,
          imageSeo: item.image_seo,
          previewVideo: item.video,
          fullVideoLink: item.link,
          title: item.title,
          descriptions: item.preview_text,
          text: item.text,
          review_media_orientation: item.review_media_orientation,
        })),
      },
      BlockReview: {
        title: block.name,
        reviews: (block.reviews as BlockReview['reviews'])?.map(item => ({
          image: item.image,
          imageSeo: item.image_seo,
          previewVideo: item.video,
          fullVideoLink: item.link,
          title: item.title,
          descriptions: item.preview_text,
          text: item.text,
          slug: item.full_slug,
          date: item.review_date,
        })),
      },
      BlockTextForm: {
        title: block.name,
        text: block.text,
        form: block.form_model,
        formTitle: block.form_title,
        background: block.background,
        isFirst: index === 0,
      },
      BlockPrice: {
        title: block.name,
        text: block.text,
        prices: block.prices,
      },
      BlockVideo: {
        title: block.name,
        link: block.link,
        image: block.image,
        imageSeo: block.image_seo,
      },
      BlockAdvantagesVideo: {
        title: block.name,
        media: (block as BlockAdvantagesVideo).media?.map(item => ({
          link: item.link,
          image: item.image,
          imageSeo: item.image_seo,
          previewVideo: item.video,
          name: item.name,
          text: item.text,
        })),
      },
      BlockOffice: {
        title: block.title,
        offices: block.offices?.map(item => ({
          title: item.title,
          workTime: item.working_time,
          address: item.address,
          phone: item.phone,
          lat: item.latitude,
          lon: item.longitude,
          image: item.image,
          rating: item.blocks.find(el => el.resourcetype === 'OfficeBlockRating'),
        })),
      },
      BlockSliderCertificates: {
        title: block.name,
        media: block.media,
      },
      BlockTabs: {
        title: block.name,
        tabs: block.media,
      },
      BlockMainBanner: {
        title: block.name,
        image: block.image,
        imageSeo: block.image_seo,
        text: block.text,
        buttonText: block.button_text,
        buttonTrigger: block.button_trigger,
        buttonPhone: {
          text: block.button_text_phone,
          phone: block.phone,
        },
      },
      BlockServiceCatalogCategory: {
        title: block.name,
        categories: block.categories,
      },
      BlockServiceCategory: {
        title: block.name,
        services: (block.media as BlockServiceCategory['media'])?.map(el => ({
          image: el.image,
          imageSeo: el.image_seo,
          title: el.title,
          slug: el.full_slug,
        })),
      },
      BlockDoctorCategory: {
        title: block.name,
        doctors: block.doctors,
      },
    };

    return blockProps[block.type || block.resourcetype];
  };

  return {
    articleBlocks,
    getBlocksProps,
  };
}
